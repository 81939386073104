.mb--0 {
  margin-bottom: 0; }

.mt--5px {
  margin-top: 5px; }

.mb--50px {
  margin-bottom: 50px !important; }

.mt--50px {
  margin-top: 50px; }

.w-200px {
  width: 200px; }

h1 {
  font-weight: 600; }

.text--black {
  color: #000000 !important; }

.text--white {
  color: #ffffff; }

.font-w500 {
  font-weight: 500; }

.font-w600 {
  font-weight: 600; }

.text--red {
  color: #CC0224; }

.text--lightBlack {
  color: #404040; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Montserrat', sans-serif; }

.montserrat {
  font-family: 'Montserrat', sans-serif; }

.one-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.background-image-holder-bottom {
  background-position: 50% bottom !important;
  top: -40px; }

.bg--yellow {
  background-color: #FED700 !important; }

.bg--darkYellow {
  background-color: #D2B627 !important; }

.bg--red {
  background-color: #CC0224; }

.bg--dark {
  background-color: #000000; }

.bg--black {
  background-color: #000000; }

.nav-container {
  background-image: url("/img/background/legadelchianti.jpg");
  background-position: center 91%; }
  @media (max-width: 991px) {
    .nav-container {
      background-color: #000000;
      background-image: none; } }

#mainMenu {
  padding: 3.397143em 0 3.857143em 0; }
  #mainMenu ul li {
    margin-left: 0.42857143em;
    margin-right: 0.42857143em; }
    #mainMenu ul li a {
      color: #ffffff;
      font-weight: 600;
      text-transform: uppercase; }
  #mainMenu #logo {
    margin-top: -35px; }
  #mainMenu .logo {
    max-height: 100px; }
  #mainMenu.bar-background ul li a {
    color: #ffffff; }
  #mainMenu.pos-fixed {
    padding: 1.397143em 0 1.857143em 0;
    -ms-box-shadow: 0 0 10px #999;
    box-shadow: 0 0 10px #999; }
    #mainMenu.pos-fixed ul li a {
      color: #000000; }
    #mainMenu.pos-fixed .logo {
      max-height: 60px;
      margin-top: -15px; }
  @media (max-width: 991px) {
    #mainMenu {
      padding-bottom: 1em; }
      #mainMenu ul li a {
        font-size: 12px; } }
  #mainMenu.bar--transparent .logo-dark {
    display: none; }
  @media (max-width: 767px) {
    #mainMenu {
      padding-top: 10px; }
      #mainMenu .bar__module {
        margin-bottom: 0; }
        #mainMenu .bar__module ul.menu-horizontal li {
          text-align: center !important; } }

@media (max-width: 767px) {
  .logo {
    max-height: 60px;
    margin-bottom: 0; } }

@media (max-width: 767px) {
  .hamburger-toggle i {
    margin-top: 15px;
    color: #ffffff; } }

@media (max-width: 767px) {
  .bar-3 {
    text-align: center; } }

.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0; }

.sfumatura {
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  height: 340px;
  background-repeat: repeat;
  background-size: contain; }

.btn--red {
  background: #CC0224;
  color: #ffffff;
  border: 1px solid #CC0224; }
  .btn--red:hover, .btn--red:focus, .btn--red:active {
    color: #ffffff; }

.btn:hover, .btn:focus, .btn:active {
  -ms-box-shadow: none;
  box-shadow: none; }

.btn--white {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff !important; }
  .btn--white .btn__text {
    color: #000000 !important; }

.btn--darkYellow {
  background-color: #D2B627;
  border-color: #D2B627;
  color: #ffffff; }
  .btn--darkYellow .btn__text {
    color: #ffffff !important; }
  .btn--darkYellow:hover, .btn--darkYellow:focus, .btn--darkYellow:active {
    background-color: #a7911f;
    border-color: #a7911f;
    color: #ffffff !important; }

.btn--darkYellow-o {
  background-color: #ffffff;
  border-color: #D2B627;
  color: #D2B627; }
  .btn--darkYellow-o .btn__text {
    color: #D2B627 !important; }
  .btn--darkYellow-o:hover, .btn--darkYellow-o:focus, .btn--darkYellow-o:active {
    background-color: #D2B627;
    border-color: #D2B627;
    color: #ffffff; }
    .btn--darkYellow-o:hover .btn__text, .btn--darkYellow-o:focus .btn__text, .btn--darkYellow-o:active .btn__text {
      color: #ffffff !important; }

form {
  width: 100%; }
  form input[type]:focus,
  form select:focus,
  form textarea:focus {
    -ms-box-shadow: none;
    box-shadow: none;
    border-color: #999; }

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  background: #CC0224; }

.breadcrumbs li {
  color: #404040; }
  .breadcrumbs li a {
    color: #404040; }

.feature-1 .feature__body p {
  line-height: 150%; }

.masonry-filter-holder:not(.masonry-filters--horizontal) .masonry__filters ul li.active {
  color: #999; }

.social-share h4 small {
  display: block;
  width: 270px;
  margin: 0 auto;
  border-top: 1px solid #ECECEC;
  margin-top: 12px;
  padding-top: 8px; }

.social-share ul li a {
  background: #ECECEC;
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  color: #000000;
  padding-top: 3px; }
  .social-share ul li a:hover, .social-share ul li a:focus, .social-share ul li a:active {
    background: #000000;
    color: #ECECEC; }

.social-share ul li:not(:last-child) {
  margin-right: 1em; }

@media (max-width: 767px) {
  .social-share ul.list-inline li {
    display: inline-block;
    margin: 0 5px; } }

.slider .slides .flickity-page-dots .dot {
  background: none;
  border: 1px solid #999;
  width: 15px;
  height: 15px; }
  .slider .slides .flickity-page-dots .dot.is-selected {
    background-color: #999; }

.slider.cover .slides .flickity-prev-next-button {
  background: none;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px; }
  .slider.cover .slides .flickity-prev-next-button.previous {
    left: 20px; }
  .slider.cover .slides .flickity-prev-next-button.next {
    right: 20px; }

.slider.cover .slides .slide .background-image-holder {
  background-position: 50% 90% !important; }

@media (max-width: 991px) {
  .slider.cover.height-70 {
    height: 60vh; }
    .slider.cover.height-70 ul .flickity-viewport {
      height: 60vh !important; }
      .slider.cover.height-70 ul .flickity-viewport .flickity-slider li {
        height: 60vh; } }

section:not(.imagebg):not([class*='bg-']) + section.bg--secondary {
  border: 0 !important; }

section.bg--secondary:not(.imagebg) + section:not(.imagebg):not([class*='bg-']) {
  border: 0 !important;
  border-top: 0 !important; }
